// Alto Parnaíba
const idPortalApi = "17d6ef31194a50915358d65cd00c43a4e4c25f2d"; //turilandia
const idForTransparenciaAdm = "935a1992-d399-47fe-b069-d8f34968b1a8";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  idForTransparenciaAdm: `${idForTransparenciaAdm}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,

  //config botões e imagens
  dnsPortal: "cmturilandia.ma.gov.br",

  NomePortal: "Turilândia",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 8, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  urlADM: `https://administracaopublica.com.br`,

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://cmturilandia.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#b3183a",
};
